import React, { createContext, useEffect, useState } from 'react';
import { auth } from '../Firebase';

const AuthContextAdmin = createContext();
const AuthContextPublic = createContext();

const AuthProvider = ({ children }) => {
  const [publicUser, setPublicUser] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setPublicUser(user);
      setAdminUser(user);

      const currentUser = auth.currentUser;
      if (currentUser) {
        setPublicUser(currentUser);
        setAdminUser(currentUser);
      }
    });
  }, []);

  const publicLogin = (password) => {
    const hardcodedPassword = 'sdso';

    if (password === hardcodedPassword) {
      auth
        .setPersistence('local')
        .then(() => {
          return auth.signInAnonymously();
        })
        .then((userCredential) => {
          setPublicUser(userCredential.user);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setError('Incorrect password');
    }
  };

  const adminLogin = (password) => {
    const hardcodedPassword = 'adminPassword';

    if (password === hardcodedPassword) {
      auth
        .setPersistence('local')
        .then(() => {
          return auth.signInAnonymously();
        })
        .then((userCredential) => {
          setAdminUser(userCredential.user);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setError('Incorrect password');
    }
  };

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        setAdminUser(null);
        setPublicUser(null);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <AuthContextAdmin.Provider
      value={{ user: adminUser, login: adminLogin, logout, error }}
    >
      <AuthContextPublic.Provider
        value={{ user: publicUser, login: publicLogin, logout, error }}
      >
        {children}
      </AuthContextPublic.Provider>
    </AuthContextAdmin.Provider>
  );
};

export { AuthProvider, AuthContextAdmin, AuthContextPublic };
