import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContextAdmin } from './AuthContext';

const HeaderAdmin = ({ name }) => {
  const { logout } = useContext(AuthContextAdmin) || {};

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand>Manage</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/admin/managelist">
              List View
            </Nav.Link>
            <Nav.Link as={Link} to="/admin/managephoto">
              Photo View
            </Nav.Link>
            <Nav.Link as={Link} to="/" target="_blank">
              Quiz
            </Nav.Link>
            <Nav.Link as={Link} to="/all">
              All
            </Nav.Link>
            <button className="btn btn-primary" onClick={logout}>
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderAdmin;
