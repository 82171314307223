import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { firestore } from '../Firebase';
import { Col, Container, Modal, Row, Button } from 'react-bootstrap';
import Header from './HeaderAdmin';
import Add from './Add';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';

const ManagePhoto = () => {
  const [people, setPeople] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedThumbnails, setSelectedThumbnails] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [isOrderModified, setIsOrderModified] = useState(false);

  useEffect(() => {
    const unsubscribe = firestore
      .collection('people')
      .orderBy('displayorder')
      .onSnapshot((snapshot) => {
        const firestoreItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Sort by displayorder
        const sortedItems = firestoreItems.sort(
          (a, b) => a.displayorder - b.displayorder
        );
        setPeople(sortedItems);
      });
    return () => unsubscribe();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleThumbnailClick = (index, id, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(index, lastSelectedIndex);
      const end = Math.max(index, lastSelectedIndex);
      const newSelectedThumbnails = [...selectedThumbnails];
      for (let i = start; i <= end; i++) {
        if (!newSelectedThumbnails.includes(people[i].id)) {
          newSelectedThumbnails.push(people[i].id);
        }
      }
      setSelectedThumbnails(newSelectedThumbnails);
    } else {
      if (selectedThumbnails.includes(id)) {
        setSelectedThumbnails(selectedThumbnails.filter((item) => item !== id));
      } else {
        setSelectedThumbnails([...selectedThumbnails, id]);
      }
    }
    setLastSelectedIndex(index);
  };

  const isThumbnailSelected = (id) => selectedThumbnails.includes(id);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const activeIndex = people.findIndex((person) => person.id === active.id);
    const overIndex = people.findIndex((person) => person.id === over.id);

    if (selectedThumbnails.includes(active.id)) {
      const selectedIds = new Set(selectedThumbnails);
      const selectedItems = people.filter((person) =>
        selectedIds.has(person.id)
      );

      let newPeople = [...people];

      // Remove selected items from their original positions
      newPeople = newPeople.filter((person) => !selectedIds.has(person.id));

      // Insert selected items at the new position
      newPeople.splice(overIndex, 0, ...selectedItems);

      setPeople(newPeople);
    } else {
      setPeople((items) => arrayMove(items, activeIndex, overIndex));
    }

    setIsOrderModified(true);
  };

  const updateDisplayOrderInFirestore = async () => {
    const updatedPeople = people.map((person, index) => ({
      ...person,
      displayorder: index + 1,
    }));
    setPeople(updatedPeople);

    const batch = firestore.batch();
    updatedPeople.forEach((person, index) => {
      const personRef = firestore.collection('people').doc(person.id);
      batch.update(personRef, { displayorder: index + 1 });
    });
    try {
      await batch.commit();
      console.log('Display order updated in Firestore.');
      setIsOrderModified(false); // Reset the order modified flag
    } catch (error) {
      console.error('Error updating display order in Firestore:', error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Header />
        <Toaster />
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <div className="h4">Add Person</div>
          </Modal.Header>
          <Modal.Body>
            <Add />
          </Modal.Body>
        </Modal>
        <Col>
          <div className="h1">People</div>
          <button
            className="btn btn-primary float-end"
            onClick={handleShowModal}
          >
            Add
          </button>
          {isOrderModified && (
            <Button
              className="btn btn-success float-end me-2"
              onClick={updateDisplayOrderInFirestore}
            >
              Save Order
            </Button>
          )}
        </Col>
      </Row>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={people.map((person) => person.id)}
          strategy={verticalListSortingStrategy}
        >
          <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            {people.map((person, index) => (
              <SortableItem
                key={person.id}
                id={person.id}
                index={index}
                person={person}
                handleThumbnailClick={handleThumbnailClick}
                isSelected={isThumbnailSelected(person.id)}
              />
            ))}
          </Row>
        </SortableContext>
      </DndContext>
    </Container>
  );
};

export default ManagePhoto;
