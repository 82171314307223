import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SortableItem = ({
  id,
  index,
  person,
  handleThumbnailClick,
  isSelected,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
    position: 'relative',
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Col
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="col col-6 col-sm-4 col-md-3 col-lg-2 py-3 text-center"
      onClick={(event) => handleThumbnailClick(index, person.id, event)}
      style={style}
    >
      <div className="theImage">
        <img className="w-100" src={person.photo} alt="" />
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: '#0d6efd',
              color: 'white',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            ✓
          </div>
        )}
      </div>
      <div className="h4 mt-1">
        {person.name} {person.lastname}
      </div>
      <div className="h5">{person.title}</div>
      <div className="h6">{person.department}</div>
      <Link
        to={`/admin/edit/${person.id}`}
        className="btn btn-warning me-2 editBtn"
      >
        Edit
      </Link>
    </Col>
  );
};
