import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { firestore } from '../Firebase';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderPublic from './HeaderPublic';

const All = () => {
  const [people, setPeople] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [sortCriteria, setSortCriteria] = useState('default'); // New state variable for sorting

  useEffect(() => {
    firestore
      .collection('people')
      .orderBy('displayorder')
      .onSnapshot((snapshot) => {
        const firestoreItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPeople(firestoreItems);

        const allDepartments = firestoreItems.reduce((acc, person) => {
          if (!acc.includes(person.department)) {
            acc.push(person.department);
          }
          return acc;
        }, []);
        allDepartments.sort();
        setDepartments(allDepartments);
      });
  }, []);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortedPeople = () => {
    let filteredPeople = people.filter(
      (person) =>
        !selectedDepartment || person.department === selectedDepartment
    );

    if (sortCriteria === 'lastname') {
      filteredPeople.sort((a, b) => a.lastname.localeCompare(b.lastname));
    } else if (sortCriteria === 'firstname') {
      filteredPeople.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortCriteria === 'default') {
      filteredPeople.sort((a, b) => a.displayorder - b.displayorder);
    }

    return filteredPeople;
  };

  return (
    <Container fluid>
      <Row>
        <Toaster />
        <HeaderPublic />
        <Col>
          <div className="h1">People</div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2 col-sm-8">
          <Row className="gx-2">
            <div className="col-auto d-flex align-items-center">
              <span className="h4">Department:</span>
            </div>
            <div className="col-auto">
              <select
                className="form-select form-select-lg"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
              >
                <option value="">All</option>
                {departments.map((dept) => (
                  <option key={dept} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto d-flex align-items-center ms-4">
              <span className="h4">Sort by:</span>
            </div>
            <div className="col-auto">
              <select
                className="form-select form-select-lg"
                value={sortCriteria}
                onChange={handleSortChange}
              >
                <option value="default">Default</option>
                <option value="firstname">First Name</option>
                <option value="lastname">Last Name</option>
              </select>
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        {sortedPeople().map((person) => (
          <Col
            key={person.id}
            className="col col-6 col-sm-4 col-md-3 col-lg-2 py-3 text-center"
          >
            <div className="theImage">
              <img className="w-100" src={person.photo} alt="" />
            </div>
            <div className="h4 mt-1">
              {person.name} {person.lastname}
            </div>
            <div className="h5">{person.title}</div>
            <div className="h6">{person.department}</div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default All;
