import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const QuizQuestion = ({
  quizPeople,
  currentIndex,
  correctClicked,
  clickedOption,
  correctAnswer,
  handleOptionClick,
  handleNext,
  options,
}) => {
  return (
    <Col md={{ span: 6, offset: 3 }} className="text-center">
      <div className="h4 mb-4">
        {currentIndex + 1}/{quizPeople.length}
      </div>
      <div className="theImage">
        <img
          src={quizPeople[currentIndex].photo}
          alt=""
          style={{ maxHeight: '300px' }}
        />
      </div>

      {correctClicked ? (
        <div>
          <h4 className="mt-1">
            {quizPeople[currentIndex].name} {quizPeople[currentIndex].lastname}
          </h4>
          <h5 className="mt-1">{quizPeople[currentIndex].title}</h5>
          <h6 className="mt-1">{quizPeople[currentIndex].department}</h6>
        </div>
      ) : (
        <div>
          <h4 className="mt-1">???</h4>
          <h5 className="mt-1">???</h5>
          <h6 className="mt-1">???</h6>
        </div>
      )}

      <Row className="mt-2">
        {options.map((option, index) => (
          <Col sm={{ span: 12 }} key={index}>
            <div className="d-grid">
              <Button
                onClick={() => handleOptionClick(option)}
                variant={
                  option === correctAnswer
                    ? correctClicked
                      ? 'success'
                      : 'primary'
                    : clickedOption === option
                    ? 'danger'
                    : 'primary'
                }
                disabled={correctClicked && option !== correctAnswer}
                className={`mx-2 btn btn-primary text-uppercase h3 py-2 rounded-pill ${
                  clickedOption === option ? 'clicked' : ''
                }`}
              >
                {option}
              </Button>
            </div>
          </Col>
        ))}
        <Col sm={{ span: 12 }}>
          <div className="d-grid">
            <Button
              onClick={handleNext}
              variant="primary"
              className="mx-2 mt-3 btn btn-primary text-uppercase h3 py-2 rounded-pill"
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default QuizQuestion;
