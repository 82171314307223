import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const QuizReport = ({
  correctFirstTimeCount,
  numQuestions,
  reportArray,
  playAgain,
}) => {
  return (
    <div className="text-center">
      <h2>Quiz Completed!</h2>
      <div className="mt-3">
        Correct on First Attempt: {correctFirstTimeCount}/{numQuestions}
      </div>
      <div className="mt-3">
        <h3>Report:</h3>
        <Row>
          <Col className="col-sm-6 offset-sm-3 col-lg-4 offset-lg-4">
            <Row>
              {reportArray.map((report, index) => (
                <Col className="col-6 mb-4" key={index}>
                  <Row>
                    <Col className="col-xs-6">
                      <img
                        src={report.photo}
                        alt=""
                        style={{ maxWidth: '100%' }}
                      />
                    </Col>
                  </Row>
                  <h4 className="mt-1">
                    {report.result} {report.name}
                  </h4>
                  <h5 className="mt-1">{report.title}</h5>
                  <h6 className="mt-1">{report.department}</h6>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }}>
            <Button
              onClick={playAgain}
              variant="primary"
              className="mx-2 mt-3 btn btn-primary text-uppercase h3 py-2 rounded-pill"
            >
              Play Again
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default QuizReport;
