import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: 'react12staffquiz.firebaseapp.com',
  projectId: 'react12staffquiz',
  storageBucket: 'react12staffquiz.appspot.com',
  messagingSenderId: '142931061933',
  appId: '1:142931061933:web:5f4a5cbb57397a5fcb2a24',
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
