import React, { useState } from 'react';
import { firestore } from '../Firebase';
import 'firebase/firestore';
import { Col, Container, Row } from 'react-bootstrap';

const ImportCSV = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const importCSVToFirestore = async () => {
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csv = event.target.result;
        const data = csvToJSON(csv);
        await writeToFirestore(data);
        alert('Data imported successfully!');
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error importing CSV:', error);
      alert('Error importing CSV:', error.message);
    }
  };

  const csvToJSON = (csv) => {
    // Your CSV to JSON conversion logic goes here
    // You can use libraries like papaparse for parsing CSV
    // For simplicity, let's assume a basic CSV to JSON conversion
    const rows = csv.split('\n');
    const headers = rows[0].split(',');
    const jsonData = [];
    for (let i = 1; i < rows.length; i++) {
      const values = rows[i].split(',');
      const obj = {};
      headers.forEach((header, index) => {
        obj[header.trim()] = values[index].trim();
      });
      jsonData.push(obj);
    }
    return jsonData;
  };

  const writeToFirestore = async (data) => {
    const batch = firestore.batch();
    const collectionRef = firestore.collection('people');
    data.forEach((doc) => {
      const docRef = collectionRef.doc();
      batch.set(docRef, doc);
    });
    await batch.commit();
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className="text-center mt-5">
          <input
            type="file"
            accept=".csv"
            className="form-control form-control-lg mb-3"
            onChange={handleFileChange}
          />
          <button className="btn btn-primary" onClick={importCSVToFirestore}>
            Import CSV to Firestore
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default ImportCSV;
