import React, { useEffect, useState } from 'react';
import { firestore } from '../Firebase';
import HeaderPublic from './HeaderPublic';
import { Container, Row } from 'react-bootstrap';
import QuizForm from './QuizForm';
import QuizQuestion from './QuizQuestion';
import QuizReport from './QuizReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCheck } from '@fortawesome/free-solid-svg-icons';

const Quiz = () => {
  const [allPeople, setAllPeople] = useState([]);
  const [quizPeople, setQuizPeople] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [clickedOption, setClickedOption] = useState('');
  const [correctClicked, setCorrectClicked] = useState(false);
  const [alreadyTried, setAlreadyTried] = useState(false);
  const [correctFirstTimeCount, setCorrectFirstTimeCount] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [reportArray, setReportArray] = useState([]);
  const [numQuestions, setNumQuestions] = useState(5);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizType, setQuizType] = useState('staff');

  useEffect(() => {
    firestore.collection('people').onSnapshot((snapshot) => {
      const firestoreItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllPeople(firestoreItems);
    });
  }, []);

  useEffect(() => {
    if (numQuestions > 0) {
      let filteredPeople;
      if (quizType === 'musician') {
        filteredPeople = allPeople.filter(
          (person) => person.department === 'Musician'
        );
      } else if (quizType === 'staff') {
        filteredPeople = allPeople.filter(
          (person) => person.department !== 'Musician'
        );
      } else {
        filteredPeople = allPeople; // both option
      }
      const shuffledPeople = filteredPeople.sort(() => Math.random() - 0.5);
      const selectedPeople = shuffledPeople.slice(0, numQuestions);
      setQuizPeople(selectedPeople);
    }
  }, [allPeople, numQuestions, quizType]);

  useEffect(() => {
    if (quizPeople.length > 0) {
      const currentPerson = quizPeople[currentIndex];
      const falseOptions = allPeople
        .filter(
          (person) =>
            person.id !== currentPerson.id &&
            person.gender === currentPerson.gender
        )
        .map((person) => person.name + ' ' + person.lastname);
      const trueOption = currentPerson.name + ' ' + currentPerson.lastname;
      const shuffledFalseOptions = shuffleArray(falseOptions).slice(0, 3);
      const allOptions = [...shuffledFalseOptions, trueOption];
      setOptions(shuffleArray(allOptions));
      setCorrectAnswer(trueOption);
    }
  }, [quizPeople, currentIndex, allPeople, correctAnswer]);

  const handleNext = () => {
    if (currentIndex === quizPeople.length - 1) {
      setCompleted(true);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quizPeople.length);
      setClickedOption('');
      setCorrectClicked(false);
      setAlreadyTried(false);
    }
  };

  const handleOptionClick = (option) => {
    setClickedOption(option);
    const currentPerson = quizPeople[currentIndex];
    const isCorrect = option === correctAnswer;

    if (!alreadyTried) {
      setReportArray((prevReport) => [
        ...prevReport,
        {
          photo: currentPerson.photo,
          name: currentPerson.name + ' ' + currentPerson.lastname,
          result: isCorrect ? (
            <FontAwesomeIcon className="text-success" icon={faCheck} />
          ) : (
            <FontAwesomeIcon className="text-danger" icon={faCircleXmark} />
          ),
          title: currentPerson.title,
          department: currentPerson.department,
        },
      ]);
    }

    if (isCorrect) {
      setCorrectClicked(true);
      if (!alreadyTried) {
        setCorrectFirstTimeCount((prevCount) => prevCount + 1);
      }
    } else {
      setCorrectClicked(false);
      setAlreadyTried(true);
    }
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const playAgain = () => {
    setQuizStarted(false); // Reset to show the form
    setCurrentIndex(0);
    setOptions([]);
    setCorrectAnswer('');
    setClickedOption('');
    setCorrectClicked(false);
    setAlreadyTried(false);
    setCorrectFirstTimeCount(0);
    setCompleted(false);
    setReportArray([]);
  };

  const handleStartQuiz = (e) => {
    e.preventDefault();
    setQuizStarted(true);
    // Trigger the quizPeople update when starting the quiz
    let filteredPeople;
    if (quizType === 'musician') {
      filteredPeople = allPeople.filter(
        (person) => person.department === 'Musician'
      );
    } else if (quizType === 'staff') {
      filteredPeople = allPeople.filter(
        (person) => person.department !== 'Musician'
      );
    } else {
      filteredPeople = allPeople; // both option
    }
    const shuffledPeople = filteredPeople.sort(() => Math.random() - 0.5);
    const selectedPeople = shuffledPeople.slice(0, numQuestions);
    setQuizPeople(selectedPeople);
  };

  return (
    <Container fluid>
      <Row>
        <HeaderPublic />
      </Row>
      <Row>
        {!quizStarted ? (
          <QuizForm
            numQuestions={numQuestions}
            setNumQuestions={setNumQuestions}
            quizType={quizType}
            setQuizType={setQuizType}
            handleStartQuiz={handleStartQuiz}
          />
        ) : completed ? (
          <QuizReport
            correctFirstTimeCount={correctFirstTimeCount}
            numQuestions={numQuestions}
            reportArray={reportArray}
            playAgain={playAgain}
          />
        ) : (
          quizPeople.length > 0 && (
            <QuizQuestion
              quizPeople={quizPeople}
              currentIndex={currentIndex}
              correctClicked={correctClicked}
              clickedOption={clickedOption}
              correctAnswer={correctAnswer}
              handleOptionClick={handleOptionClick}
              handleNext={handleNext}
              options={options}
            />
          )
        )}
      </Row>
    </Container>
  );
};

export default Quiz;
