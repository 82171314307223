import React, { useEffect, useState, useRef, useCallback } from 'react';
import { firestore, storage } from '../Firebase';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment-timezone';
import { BeatLoader } from 'react-spinners';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import debounce from 'lodash.debounce';
import DropzoneManage from './DropzoneManage';
import Add from './Add';
import HeaderAdmin from './HeaderAdmin';

const ManageList = () => {
  const [people, setPeople] = useState([]);
  const [localInputs, setLocalInputs] = useState({});
  const [updatedFields, setUpdatedFields] = useState({});
  const [sortBy, setSortBy] = useState({ field: 'displayorder', order: 'asc' });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchData(sortBy);
  }, [sortBy]);

  const fetchData = (sortBy) => {
    firestore
      .collection('people')
      .orderBy(sortBy.field, sortBy.order)
      .onSnapshot((snapshot) => {
        let firestoreItems = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        firestoreItems.sort((a, b) => {
          const numA = a.displayorder ?? 0;
          const numB = b.displayorder ?? 0;
          return numA - numB;
        });

        setPeople(firestoreItems);
        const inputs = firestoreItems.reduce((acc, person) => {
          acc[person.id] = {
            name: person.name,
            lastname: person.lastname,
            gender: person.gender,
            title: person.title,
            department: person.department,
            displayorder: person.displayorder,
          };
          return acc;
        }, {});
        setLocalInputs(inputs);
      });
  };

  const debouncedUpdate = useRef(
    debounce((newValue, field, personID) => {
      firestore
        .collection('people')
        .doc(personID)
        .update({
          [field]: newValue,
        })
        .then(() => {
          setUpdatedFields((prevFields) => ({
            ...prevFields,
            [personID]: { ...prevFields[personID], [field]: true },
          }));
          setTimeout(() => {
            setUpdatedFields((prevFields) => ({
              ...prevFields,
              [personID]: { ...prevFields[personID], [field]: false },
            }));
          }, 100);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    }, 500)
  ).current;

  const handleUpdate = useCallback(
    (newValue, field, personID) => {
      setLocalInputs((prevInputs) => ({
        ...prevInputs,
        [personID]: {
          ...prevInputs[personID],
          [field]: newValue,
        },
      }));
      debouncedUpdate(newValue, field, personID);
    },
    [debouncedUpdate]
  );

  const handleSort = (field) => {
    setSortBy((prevSortBy) => ({
      field,
      order:
        prevSortBy.field === field && prevSortBy.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const onDropHandler = async ({ name, uri, person }) => {
    if (name) {
      setLoading(true);

      const storageRef = storage.ref();

      let nowTime = moment();
      let formattedDate = nowTime
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD_HH:mm:ss');

      const filenameParts = name.split('.');
      const filenameWithoutExtension = filenameParts.join('.');
      const renamedFilename = `${filenameWithoutExtension}_${formattedDate}.jpg`;

      const fileRef = storageRef.child(renamedFilename);
      try {
        await fileRef.put(uri);
        const downloadURL = await fileRef.getDownloadURL();
        const personRef = firestore.collection('people').doc(person);
        await personRef.update({ photo: downloadURL });
        setLoading(false);
        toast.success('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error(
          'An error occurred while uploading the file. Please try again.'
        );
        setLoading(false);
        throw error;
      }
    }
  };

  const deletePerson = (id) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this person?'
    );

    if (confirmDelete) {
      firestore
        .collection('people')
        .doc(id)
        .delete()
        .then(() => {
          toast.success('Document successfully deleted!');
        })
        .catch((error) => {
          toast.error('Error removing document: ', error);
        });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container fluid>
        <Row>
          <HeaderAdmin />
          <Toaster />
          {loading && (
            <div className="overlay">
              <BeatLoader
                color="#36d7b7"
                size={15}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <div className="h4">Add Person</div>
            </Modal.Header>
            <Modal.Body>
              <Add totalPeople={people.length} />
            </Modal.Body>
          </Modal>
        </Row>
        <Row>
          <Col>
            <div className="h1">People</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              className="btn btn-primary float-end mb-1"
              onClick={handleShowModal}
            >
              Add
            </button>
          </Col>
        </Row>
        <Row>
          <table id="csvTable" className="table table-striped table-hover">
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#fff',
              }}
            >
              <tr>
                <th>Photo</th>
                <th onClick={() => handleSort('name')}>Name</th>
                <th onClick={() => handleSort('lastname')}>Last Name</th>
                <th onClick={() => handleSort('gender')}>Gender</th>
                <th onClick={() => handleSort('title')}>Title</th>
                <th onClick={() => handleSort('department')}>Department</th>
                <th>DisplayOrder</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {people.map((person) => (
                <tr key={person.id}>
                  <td>
                    <DropzoneManage
                      onDropHandler={onDropHandler}
                      person={person.id}
                      photo={person.photo}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        updatedFields[person.id]?.name &&
                        'border border-success border-2'
                      }`}
                      value={localInputs[person.id]?.name || ''}
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'name', person.id)
                      }
                      style={{ width: '15em' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        updatedFields[person.id]?.lastname &&
                        'border border-success border-2'
                      }`}
                      value={localInputs[person.id]?.lastname || ''}
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'lastname', person.id)
                      }
                      style={{ width: '15em' }}
                    />
                  </td>
                  <td>
                    <select
                      className={`form-control ${
                        updatedFields[person.id]?.gender &&
                        'border border-success border-2'
                      }`}
                      name="gender"
                      placeholder="gender"
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'gender', person.id)
                      }
                      value={localInputs[person.id]?.gender || ''}
                    >
                      <option value=""></option>
                      <option value="male">M</option>
                      <option value="female">F</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        updatedFields[person.id]?.title &&
                        'border border-success border-2'
                      }`}
                      value={localInputs[person.id]?.title || ''}
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'title', person.id)
                      }
                      style={{ width: '18em' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        updatedFields[person.id]?.department &&
                        'border border-success border-2'
                      }`}
                      value={localInputs[person.id]?.department || ''}
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'department', person.id)
                      }
                      style={{ width: '18em' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        updatedFields[person.id]?.displayorder &&
                        'border border-success border-2'
                      }`}
                      value={localInputs[person.id]?.displayorder || ''}
                      onChange={(e) =>
                        handleUpdate(e.target.value, 'displayorder', person.id)
                      }
                      style={{ width: '4em' }}
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => deletePerson(person.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </Container>
    </DndProvider>
  );
};

export default ManageList;
