import './App.css';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginComponentPublic from './components/LoginComponentPublic';
import LoginComponentAdmin from './components/LoginComponentAdmin';
import ManageList from './components/ManageList';
import ManagePhoto from './components/ManagePhoto';
import Add from './components/Add';
import Edit from './components/Edit';
import ImportCSV from './components/ImportCSV';
import All from './components/All';
import Quiz from './components/Quiz';
import { AuthContextAdmin, AuthContextPublic } from './components/AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

function App() {
  return (
    <Routes>
      <Route path="/all" element={<All />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="*" element={<PublicRoutes />} />
    </Routes>
  );
}

function PublicRoutes() {
  const { user } = useContext(AuthContextPublic) || {};

  return (
    <>
      {user ? (
        <Routes>
          <Route path="/" element={<Quiz />}></Route>
        </Routes>
      ) : (
        <LoginComponentPublic />
      )}
    </>
  );
}

function AdminRoutes() {
  const { user } = useContext(AuthContextAdmin) || {};

  return (
    <>
      {user ? (
        <Routes>
          <Route path="/managelist" element={<ManageList />}></Route>
          <Route path="/managephoto" element={<ManagePhoto />}></Route>
          <Route path="/" element={<ManageList />}></Route>
          <Route path="/add" element={<Add />}></Route>
          <Route path="/edit/:personID" element={<Edit />}></Route>
          <Route path="/import" element={<ImportCSV />}></Route>
        </Routes>
      ) : (
        <LoginComponentAdmin />
      )}
    </>
  );
}

export default App;
