import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, storage } from '../Firebase';
import { BeatLoader } from 'react-spinners';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import Dropzone from './Dropzone';

const Edit = () => {
  const [loading, setLoading] = useState(false);
  const { personID } = useParams();
  const initialState = {
    id: personID,
    name: '',
    lastname: '',
    gender: '',
    title: '',
    department: '',
  };
  const [person, setPerson] = useState(initialState);
  const [imageName, setImageName] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchPersonData(personID);
  }, [personID]);

  const fetchPersonData = async (personID) => {
    try {
      const itemRef = firestore.collection('people').doc(personID);
      const doc = await itemRef.get();
      if (doc.exists) {
        setPerson(doc.data());
      } else {
        console.log('No such person!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerson((personArgument) => ({
      ...personArgument,
      [name]: value,
    }));
  };

  const onDropHandler = ({ name, uri }) => {
    setImageName(name);
    setImageSrc(uri);
    setUploaded(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      let downloadURL = person.photo; // Initialize downloadURL with the current photo

      if (uploaded) {
        downloadURL = await handleFileUpload();

        // Update the photo field in Firestore separately
        const personRef = firestore.collection('people').doc(personID);
        await personRef.update({ photo: downloadURL });
      }

      // Update the person state with other fields
      const updatedPerson = {
        ...person,
        photo: downloadURL, // Update the photo field in the updatedPerson object
      };

      const personRef = firestore.collection('people').doc(personID);
      await personRef.update(updatedPerson);

      setLoading(false);
      toast.success('Person updated successfully.');
      navigate('/admin/managephoto');
    } catch (error) {
      console.error('Error updating person:', error);
      const errorMessage = error.message || 'An error occurred.';
      toast.error(errorMessage);
    }
  };

  const handleFileUpload = async () => {
    if (imageName) {
      const storageRef = storage.ref();

      let nowTime = moment();
      let formattedDate = nowTime
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD_HH:mm:ss');

      const filenameParts = imageName.split('.');
      const filenameWithoutExtension = filenameParts.join('.');
      const renamedFilename = `${filenameWithoutExtension}_${formattedDate}.jpg`;

      const fileRef = storageRef.child(renamedFilename);
      try {
        await fileRef.put(imageSrc);
        const downloadURL = await fileRef.getDownloadURL();

        return downloadURL;
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error(
          'An error occurred while uploading the file. Please try again.'
        );
        throw error;
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col className="col-sm-6 offset-sm-3">
          <Toaster />
          {loading && (
            <div className="overlay">
              <BeatLoader
                color="#36d7b7"
                size={15}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <button
            className="btn btn-secondary mb-3 mt-1"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h2>Edit Person</h2>
          <div className="theImage">
            <img className="w-100" src={person.photo} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <Dropzone onDropHandler={onDropHandler} />

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="name"
                onChange={handleChange}
                value={person.name}
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="lastname"
                placeholder="lastname"
                onChange={handleChange}
                value={person.lastname}
              />
              <label htmlFor="lastname">Last Name</label>
            </div>
            <div className="form-floating mb-3">
              <select
                className="form-control"
                name="gender"
                placeholder="gender"
                onChange={handleChange}
                value={person.gender}
              >
                <option value=""></option>
                <option value="male">M</option>
                <option value="female">F</option>
              </select>
              <label htmlFor="gender">Gender</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="title"
                placeholder="title"
                onChange={handleChange}
                value={person.title}
              />
              <label htmlFor="title">Title</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="department"
                placeholder="department"
                onChange={handleChange}
                value={person.department}
              />
              <label htmlFor="department">Department</label>
            </div>
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Edit;
