import React from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';

const QuizForm = ({
  numQuestions,
  setNumQuestions,
  quizType,
  setQuizType,
  handleStartQuiz,
}) => {
  return (
    <Col md={{ span: 6, offset: 3 }}>
      <div>
        <div className="h2 mt-3">Welcome to the SDSO Staff Quiz!</div>
        <div className="h5 mb-5">
          To get started, select the number of questions you want and the type
          of quiz:
        </div>
      </div>
      <Form onSubmit={handleStartQuiz} className="text-center">
        <Form.Group controlId="numQuestions">
          <Row className="align-items-center">
            <Col xs="auto">
              <Form.Label className="h4 mb-0">Show:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                as="select"
                value={numQuestions}
                onChange={(e) => setNumQuestions(parseInt(e.target.value))}
                required
                className="form-select"
                style={{ textAlign: 'center' }}
              >
                <option value={5}>5 people</option>
                <option value={10}>10 people</option>
                <option value={15}>15 people</option>
                <option value={20}>20 people</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="quizType">
          <Row className="align-items-center mt-2">
            <Col xs="auto">
              <Form.Label className="h4 mb-0">Include:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                as="select"
                value={quizType}
                onChange={(e) => setQuizType(e.target.value)}
                required
                className="form-select"
                style={{ textAlign: 'center' }}
              >
                <option value="staff">Staff Only</option>
                <option value="musician">Musicians Only</option>
                <option value="both">Staff and Musicians</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <Button type="submit" variant="primary" className="mt-3">
          Start Quiz
        </Button>
      </Form>
    </Col>
  );
};

export default QuizForm;
