import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const resizeAndCropImage = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const size = 400;

      // Set canvas size to 400x400
      canvas.width = size;
      canvas.height = size;

      // Calculate crop area
      const minDim = Math.min(img.width, img.height);
      const sx = (img.width - minDim) / 2;
      const sy = (img.height - minDim) / 2;

      ctx.drawImage(img, sx, sy, minDim, minDim, 0, 0, size, size);

      canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
          });
          callback(resizedFile);
        },
        'image/jpeg',
        0.8
      );
    };
  };
  reader.readAsDataURL(file);
};

const Dropzone = ({ onDropHandler, imageName }) => {
  const [thumbSrc, setThumbSrc] = useState(null);

  useEffect(() => {
    if (imageName === null) {
      setThumbSrc(null);
    }
  }, [imageName]);

  const onDrop = async (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      resizeAndCropImage(file, (resizedFile) => {
        const dataURL = URL.createObjectURL(resizedFile);
        setThumbSrc(dataURL);
        onDropHandler({ name: file.name, uri: resizedFile });
      });
    });
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop });

  const dzStyle = useMemo(
    () =>
      [
        'baseStyle',
        isFocused ? 'focusedStyle' : '',
        isDragAccept ? 'acceptStyle' : '',
        isDragReject ? 'rejectStyle' : '',
      ].join(' '),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <section className="dzContainer mb-3">
      <div {...getRootProps()} className={dzStyle}>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <button type="button" className="btn btn-primary">
          Upload File
        </button>
      </div>
      {thumbSrc && (
        <aside className="thumbsContainer">
          <div className="thumb">
            <div className="thumbInner">
              {thumbSrc && (
                <img src={thumbSrc} alt={`uploaded`} className="img" />
              )}
            </div>
          </div>
        </aside>
      )}
    </section>
  );
};

export default Dropzone;
