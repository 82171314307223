import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { firestore, storage } from '../Firebase';
import Dropzone from './Dropzone';
import { BeatLoader } from 'react-spinners';
import moment from 'moment-timezone';

const Add = ({ totalPeople }) => {
  const [person, setPerson] = useState({
    photo: '',
    name: '',
    lastname: '',
    gender: '',
    title: '',
    department: '',
  });
  const [imageName, setImageName] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerson((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onDropHandler = ({ name, uri }) => {
    setImageName(name);
    setImageSrc(uri);
    setPerson((prevState) => ({
      ...prevState,
      photo: name,
    }));
    setUploaded(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!uploaded) {
      toast.error('Please submit an image file.');
      return;
    }

    if (!person.name) {
      toast.error('Please enter a name.');
      return;
    }

    if (!person.lastname) {
      toast.error('Please enter a last name.');
      return;
    }

    if (!person.gender) {
      toast.error('Please enter gender.');
      return;
    }

    if (!person.title) {
      toast.error('Please enter a title.');
      return;
    }

    if (!person.department) {
      toast.error('Please enter a department.');
      return;
    }

    let nowTime = moment();
    let formattedDate = nowTime
      .tz('America/Los_Angeles')
      .format('YYYY-MM-DD_HH:mm:ss');

    try {
      setLoading(true);

      const downloadURL = await handleFileUpload();

      await firestore.collection('people').add({
        photo: downloadURL,
        name: person.name,
        lastname: person.lastname,
        gender: person.gender,
        title: person.title,
        department: person.department,
        date_added: formattedDate,
        displayorder: totalPeople + 1,
      });

      setPerson({
        photo: '',
        name: '',
        lastname: '',
        gender: '',
        title: '',
        department: '',
      });
      setImageName(null);
      setImageSrc(null);

      toast.success(person.name + ' added successfully.');
    } catch (error) {
      console.error('Error adding person:', error);
      toast.error(
        'An error occurred while adding the person. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    if (imageName) {
      const storageRef = storage.ref();

      let nowTime = moment();
      let formattedDate = nowTime
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD_HH:mm:ss');

      const filenameParts = imageName.split('.');
      const filenameWithoutExtension = filenameParts.join('.');
      const renamedFilename = `${filenameWithoutExtension}_${formattedDate}.jpg`;

      const fileRef = storageRef.child(renamedFilename);
      try {
        await fileRef.put(imageSrc);
        const downloadURL = await fileRef.getDownloadURL();
        return downloadURL;
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error(
          'An error occurred while uploading the file. Please try again.'
        );
        throw error;
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <BeatLoader
            color="#36d7b7"
            size={15}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <Dropzone onDropHandler={onDropHandler} imageName={imageName} />
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="name"
            onChange={handleChange}
            value={person.name}
          />
          <label htmlFor="name">Name</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="lastname"
            placeholder="lastname"
            onChange={handleChange}
            value={person.lastname}
          />
          <label htmlFor="lastname">Last Name</label>
        </div>
        <div className="form-floating mb-3">
          <select
            className="form-control"
            name="gender"
            placeholder="gender"
            onChange={handleChange}
            value={person.gender}
          >
            <option value=""></option>
            <option value="male">M</option>
            <option value="female">F</option>
          </select>
          <label htmlFor="gender">Gender</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="title"
            onChange={handleChange}
            value={person.title}
          />
          <label htmlFor="title">Title</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            name="department"
            placeholder="department"
            onChange={handleChange}
            value={person.department}
          />
          <label htmlFor="department">Department</label>
        </div>
        <div className="col-12 mb-5">
          <input type="submit" className="btn btn-primary mb-5" />
        </div>
      </form>
    </>
  );
};

export default Add;
